// @flow
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import * as Styled from './styled'
import type { TSocialImageProps } from './types'
import { ButtonsWrapper, RemoveButton, UploadDescription } from '../styled'
import {
  SOCIAL_IMAGE_SIZE,
  SOCIAL_IMAGE_TOOLTIP_IMAGE,
  SOCIAL_COVER_IMAGE
} from './consts'
import { BLANK_IMAGE_URL } from '@website/conf/asset-urls'
import { default as Button } from '../UploadButton'
import MediaUploadButton from '@editor/common/components/MediaUploadButton'
import { ImageUploadInfo } from '@editor/common/utils/image'
import { isBlobUrl } from '@editor/common/utils'
import { optimizeImage } from '@actions/utility'
import Image from '@website/common/components/Image/Image'
import { translate } from '@editor/common/utils/translations'
import { socialImageSizes } from '@editor/conf/imageSizes'

const SocialImage = ({
  socialImageData,
  setSocialImageData
}: TSocialImageProps) => {
  const { siteId } = useParams()
  const { socialImage } = socialImageData
  const socialImageUrl = socialImage.toString()

  const removeSocialImage = useCallback(() => {
    setSocialImageData({
      socialImage: '',
      socialImageWidth: 0,
      socialImageHeight: 0
    })
  }, [setSocialImageData])

  const handleChoose = useCallback(
    (url: string, imageData = {}) => {
      const { width = 0, height = 0 } = imageData
      setSocialImageData({
        socialImage: new ImageUploadInfo({
          failed: false,
          isLoading: true,
          url: url
        }),
        socialImageWidth: width,
        socialImageHeight: height
      })

      if (isBlobUrl(url)) {
        return
      }

      optimizeImage(url, socialImageSizes, siteId)
        .then(({ imgDimensions }) => {
          setSocialImageData({
            socialImage: new ImageUploadInfo({
              failed: false,
              isLoading: false,
              url: imgDimensions.small
            }),
            socialImageWidth: width,
            socialImageHeight: height
          })
        })
        .catch(() => {
          setSocialImageData({
            socialImage: new ImageUploadInfo({
              failed: true,
              isLoading: false,
              url: url
            }),
            socialImageWidth: 0,
            socialImageHeight: 0
          })
        })
    },
    [setSocialImageData, siteId]
  )

  const handleError = useCallback(() => {
    setSocialImageData({
      socialImage: new ImageUploadInfo({
        failed: true,
        isLoading: false,
        url: socialImageUrl || BLANK_IMAGE_URL
      }),
      socialImageWidth: 0,
      socialImageHeight: 0
    })
  }, [setSocialImageData, socialImageUrl])

  return (
    <Styled.Container>
      <Styled.Title
        title={translate('general_social_image_label')}
        tooltipText={translate('general_social_image_tooltip_text')}
        imgSrc={SOCIAL_IMAGE_TOOLTIP_IMAGE}
        hasTooltipContent
      />
      <Styled.MainContent>
        <Styled.ImageWrapper>
          {socialImageUrl ? (
            <Image
              as={Styled.Image}
              defaultImgSrc={socialImage}
              sizes={{
                small: socialImageUrl,
                medium: socialImageUrl,
                large: socialImageUrl,
                xlarge: socialImageUrl
              }}
              alt="Social image"
            />
          ) : (
            <Styled.Image src={SOCIAL_COVER_IMAGE} alt="favicon" />
          )}
        </Styled.ImageWrapper>
        <div>
          <ButtonsWrapper>
            <MediaUploadButton
              accept="image/jpeg, image/jpg, image/png, image/gif"
              disabled={socialImage.isLoading}
              insert={handleChoose}
              onError={handleError}
            >
              <Button isReplace={!!socialImageUrl} />
            </MediaUploadButton>
            {socialImageUrl && !socialImage.isLoading ? (
              <RemoveButton onClick={removeSocialImage}>
                {translate('remove_label')}
              </RemoveButton>
            ) : null}
          </ButtonsWrapper>
          <UploadDescription>
            <b>{translate('recommended_size_label')}</b> {SOCIAL_IMAGE_SIZE}
          </UploadDescription>
        </div>
      </Styled.MainContent>
    </Styled.Container>
  )
}

export default SocialImage
