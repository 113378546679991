export const FAVICON_TITLE = 'Favicon'
export const FAVICON_TOOLTIP_TEXT =
  'A favicon displays next to a page’s title in browser tabs. Adding a custom favicon makes your site recognizable in a browser full of tabs or bookmarks.'
export const FAVICON_SIZE = '32x32px'
export const FAVICON_FORMAT = '.png / .ico'

export const FAVICON_TOOLTIP_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/favicon-tooltip.svg'
export const FAVICON_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/favicon_image.svg'
