// @flow
import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import { UploadButtonWrapper } from './styled'
import type { TUploadButtonProps } from './types'
import UploadIcon from '@editor/common/assets/svgr-icons/Upload.svg'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'

const UploadButton = ({ isReplace }: TUploadButtonProps) => {
  return (
    <UploadButtonWrapper>
      <button>
        {isReplace ? <ReplaceIcon /> : <UploadIcon />}
        {isReplace ? translate('replace_label') : translate('upload_label')}
      </button>
    </UploadButtonWrapper>
  )
}

export default memo(UploadButton)
