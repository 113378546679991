// @flow
import React, { memo, useCallback, useState, useEffect } from 'react'

import * as Styled from './styled'
import type { TWebsiteNameProps } from './types'

import { KEY_CODES } from '@website/conf/consts'
import { translate } from '@editor/common/utils/translations'
import {
  InputContainer,
  Title,
  Description,
  InputWrapper,
  Input,
  ErrorMessage,
  ErrorIcon
} from '../../../shared-components/styled'
import { useKeyPress } from '@hooks'

const CHARACTER_LIMIT_ERROR_MESSAGE = translate(
  'website_name_validation_message'
)

const WebsiteName = ({ value, setValue, siteName }: TWebsiteNameProps) => {
  const [isInputActive, setInputActiveState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const setDefaultName = useCallback(
    e => {
      setInputActiveState(false)
      setErrorMessage('')

      if (value === '') {
        setValue(siteName)
      }
      e.target.blur()
    },
    [value]
  )

  useKeyPress({
    onPress: setDefaultName,
    keyCode: KEY_CODES.ENTER
  })

  const handleChange = useCallback(e => {
    const { value: _value } = e.target

    if (_value.length > 70) {
      setErrorMessage(CHARACTER_LIMIT_ERROR_MESSAGE)
      return
    }

    setValue(_value)
    setErrorMessage('')
  }, [])

  const handleBlur = useCallback(
    e => {
      setDefaultName(e)
    },
    [setDefaultName]
  )

  const handleFocus = useCallback(() => {
    setInputActiveState(true)
  }, [isInputActive])

  return (
    <InputContainer>
      <Title>{translate('website_name_label')}</Title>
      <Description>{translate('website_name_description')}</Description>
      <InputWrapper>
        <Input
          type="text"
          value={value}
          hasError={!!errorMessage}
          isInputActive={isInputActive}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {errorMessage && (
          <ErrorMessage>
            <ErrorIcon name="warning" size="xnormal" color="#ff4c4c" />
            {errorMessage}
          </ErrorMessage>
        )}
      </InputWrapper>
    </InputContainer>
  )
}

export default memo(WebsiteName)
