import styled from 'styled-components'

export const UpgradeBannerWrapper = styled.div`
  .upgrade-banner {
    img {
      width: 110px;
      height: 119px;
      border-bottom-left-radius: 10px;
      @media only screen and (max-width: 665px) {
        top: 15px;
        bottom: auto;
        border-bottom-left-radius: 0;
      }
    }
    @media only screen and (max-width: 665px) {
      height: auto;
    }
  }
`
