// @flow

import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import Icon from '@website/common/components/Icon'
import * as Styled from './styled'
import type { THorizontalScrollWrapperProps } from './types'

const HorizontalScrollWrapper = ({
  children,
  className = '',
  scrollToId
}: THorizontalScrollWrapperProps) => {
  const [showLeftIcon, setLeftIconVisibleState] = useState(false)
  const [showRightIcon, setRightIconVisibleState] = useState(false)

  const scrollableElemRef = useRef(null)
  const { current: scrollableElem } = scrollableElemRef

  useEffect(() => {
    if (!scrollToId) {
      return
    }
    const elem = document.getElementById(scrollToId)

    elem &&
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 0)
  }, [scrollToId])

  const handleScrollableElemRef = useCallback(el => {
    if (el && el.clientWidth < el.scrollWidth) {
      setRightIconVisibleState(true)
    }
    scrollableElemRef.current = el
  }, [])

  const handleScroll = useCallback(({ target }) => {
    if (target.scrollWidth - target.scrollLeft === target.clientWidth) {
      setRightIconVisibleState(false)
    } else {
      setRightIconVisibleState(true)
    }

    if (target.scrollLeft > 0) {
      setLeftIconVisibleState(true)
    } else {
      setLeftIconVisibleState(false)
    }
  }, [])

  const onLeftIconClick = useCallback(() => {
    if (scrollableElem) {
      scrollableElem.scrollLeft -= 50
    }
  }, [scrollableElem])

  const onRightIconClick = useCallback(() => {
    if (scrollableElem) {
      scrollableElem.scrollLeft += 50
    }
  }, [scrollableElem])

  return (
    <Styled.Container className={className}>
      {showLeftIcon ? (
        <Styled.IconWrapper className="left">
          <Icon
            name="back_arrow"
            size="middle"
            color="#545F7E"
            onClick={onLeftIconClick}
          />
        </Styled.IconWrapper>
      ) : null}
      <Styled.ScrollableContainer
        onScroll={handleScroll}
        ref={handleScrollableElemRef}
      >
        {children}
      </Styled.ScrollableContainer>
      {showRightIcon ? (
        <Styled.IconWrapper className="right">
          <Icon
            name="next_arrow"
            size="middle"
            color="#545F7E"
            onClick={onRightIconClick}
          />
        </Styled.IconWrapper>
      ) : null}
    </Styled.Container>
  )
}

export default memo(HorizontalScrollWrapper)
