import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`

export const ScrollableContainer = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: calc(100% - 40px);
  ::-webkit-scrollbar {
    display: none;
  }
`

export const IconWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  position: absolute;
  height: 100%;
  top: 0;
  box-sizing: border-box;
  &.left {
    left: 0;
    justify-content: flex-start;
    padding-left: 5px;
    background: linear-gradient(
      90deg,
      white,
      white,
      white,
      rgba(255, 255, 255, 0.001)
    );
  }
  &.right {
    right: 0;
    justify-content: flex-end;
    padding-right: 5px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.001),
      white,
      white,
      white
    );
  }
`
