import styled from 'styled-components'

import { Button } from '../../../styled'

export const ButtonsWrapper = styled.div`
  display: flex;
`

export const RemoveButton = styled(Button)`
  color: #387dff;
  background: #ffffff;
  margin-left: 10px;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`

export const UploadButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background: #eef5ff;
    border-radius: 5px;
    padding: 8px 18px;
    color: #387dff;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    user-select: none;
    cursor: pointer;
    border: none;
    svg {
      fill: #387dff;
      margin-right: 5px;
    }

    &:hover {
      color: #ffffff;
      background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%)
        0% 0% no-repeat padding-box;
      svg {
        fill: #ffffff;
      }
    }
  }
`

export const UploadDescription = styled.p`
  font-size: 12px;
  color: #545f7e;
  padding: 10px 0 20px;
`
