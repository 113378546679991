import styled from 'styled-components'

export const MainContent = styled.div`
  display: flex;
  padding-top: 20px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const Container = styled.div`
  position: relative;
  border-bottom: 1px solid #dee3f0;
  margin-bottom: 20px;
`

export const ImageWrapper = styled.div`
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
  & > div > div > div {
    transform: scale(0.4);
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`
