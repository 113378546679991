import styled, { createGlobalStyle } from 'styled-components'

import { IconTextButton } from '../../../shared-components'

export const GlobalStyles = createGlobalStyle`
  .sitemap-button-tooltip {
    width: fit-content;
  }
  .sitemap-button-tooltip_content {
    & > p {
      max-width: 160px;
    }
  }
`

export const ViewSitemapButton = styled(IconTextButton)`
  margin-top: 15px;

  svg {
    width: 14px;
    height: 14px;
  }
`
