// @flow
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import type { TWebsiteEmailProps } from './types'

import {
  WEBSITE_EMAIL_TOOLTIP_IMAGE,
  WEBSITE_EMAIL_UPGRADE_IMAGE,
  WEBSITE_EMAIL_UPGRADE_HOVER_IMAGE
} from './consts'
import { TitleContainer, UpgradeBanner } from '../../../shared-components'
import {
  InputContainer,
  Description,
  InputWrapper,
  Input,
  ErrorMessage,
  ErrorIcon
} from '../../../shared-components/styled'
import { isSubscriber } from '@selectors'
import { isValidEmail } from '@website/common/utils'

const WebsiteEmail = ({
  email,
  setEmail,
  isSubscriber
}: TWebsiteEmailProps) => {
  const [isInputActive, setInputActiveState] = useState(false)

  const isEmailInvalid = email && !isValidEmail(email)
  const showError = isEmailInvalid && !isInputActive

  const handleFocus = useCallback(() => {
    setInputActiveState(true)
  }, [])

  const handleBlur = useCallback(() => {
    setInputActiveState(false)
  }, [])

  const handleEmailChange = useCallback(
    e => {
      setEmail(e.target.value)
    },
    [setEmail]
  )

  return isSubscriber ? (
    <InputContainer>
      <TitleContainer
        title={translate('website_email_label')}
        tooltipText={translate('website_email_tooltip_text')}
        imgSrc={WEBSITE_EMAIL_TOOLTIP_IMAGE}
        hasTooltipContent
      />
      <Description>{translate('enter_your_email_label')}</Description>
      <InputWrapper>
        <Input
          type="text"
          value={email}
          placeholder="youremail@gmail.com"
          hasError={showError}
          isInputActive={isInputActive}
          onChange={handleEmailChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {showError && (
          <ErrorMessage>
            <ErrorIcon name="warning" size="xnormal" color="#ff4c4c" />
            {translate('email_invalid_message')}
          </ErrorMessage>
        )}
      </InputWrapper>
    </InputContainer>
  ) : (
    <Styled.UpgradeBannerWrapper>
      <UpgradeBanner
        className="upgrade-banner"
        customTranslationAlignment={120}
        img={WEBSITE_EMAIL_UPGRADE_IMAGE}
        hoverImg={WEBSITE_EMAIL_UPGRADE_HOVER_IMAGE}
        info={translate('upgrade_website_email_label')}
      />
    </Styled.UpgradeBannerWrapper>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state)
})

export default connect(mapStateToProps)(WebsiteEmail)
