//@flow

import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import * as Styled from './styled'
import type { TSitemapProps } from './types'
import { SITEMAP_TOOLTIP_IMAGE, SITEMAP_PUBLISHED_DATE } from './consts'
import {
  InputContainer,
  TitleContainer,
  Description
} from '../../../shared-components'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import NewTabIcon from '@editor/common/assets/svgr-icons/new_tab.svg'
import { openUrl } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'
import {
  getInitialSubdomain,
  selectDomain,
  selectDomainCertifiedStatus,
  selectSitePublishedDate,
  selectSitePublishedState
} from '@selectors'

const Sitemap = ({
  domain,
  subdomain,
  sitePublishedDate,
  hasCertificate,
  isPublished
}: TSitemapProps) => {
  const hasPublishedBeforeSitemap =
    isPublished &&
    moment(sitePublishedDate).isBefore(moment(SITEMAP_PUBLISHED_DATE), 'second')

  const viewSitemap = useCallback(() => {
    const protocol = hasCertificate ? 'https://' : 'http://'
    const _domain = domain || `${subdomain}.renderforestsites.com`

    openUrl(`${protocol}${_domain}/sitemap.xml`)
  }, [domain, subdomain, hasCertificate])

  return (
    <>
      <Styled.GlobalStyles />
      <InputContainer>
        <TitleContainer
          title={translate('sitemap_label')}
          tooltipText={translate('sitemap_tooltip_text')}
          imgSrc={SITEMAP_TOOLTIP_IMAGE}
          hasTooltipContent
          isTooltipFromRight
        />
        <Description>{translate('sitemap_description')}</Description>
        <TooltipWrapper
          text={
            !isPublished
              ? translate('inactive_sitemap_button_not_published')
              : hasPublishedBeforeSitemap
              ? translate('inactive_sitemap_button_already_published')
              : ''
          }
          className="sitemap-button-tooltip"
        >
          <Styled.ViewSitemapButton
            onClick={viewSitemap}
            disabled={!isPublished || hasPublishedBeforeSitemap}
          >
            <NewTabIcon />
            {translate('sitemap_button_text')}
          </Styled.ViewSitemapButton>
        </TooltipWrapper>
      </InputContainer>
    </>
  )
}

const mapStateToProps = state => ({
  domain: selectDomain(state),
  subdomain: getInitialSubdomain(state),
  hasCertificate: selectDomainCertifiedStatus(state),
  isPublished: selectSitePublishedState(state),
  sitePublishedDate: selectSitePublishedDate(state)
})

export default connect(mapStateToProps)(Sitemap)
