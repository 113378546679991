// @flow
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import * as Styled from './styled'
import type { TFaviconProps } from './types'
import Image from '@website/common/components/Image'
import { translate } from '@editor/common/utils/translations'

import { ButtonsWrapper, UploadDescription, RemoveButton } from '../styled'
import {
  FAVICON_SIZE,
  FAVICON_FORMAT,
  FAVICON_TOOLTIP_IMAGE,
  FAVICON_IMAGE
} from './consts'
import { BLANK_IMAGE_URL } from '@website/conf/asset-urls'
import { TitleContainer } from '../../../shared-components'
import { default as Button } from '../UploadButton'
import MediaUploadButton from '@editor/common/components/MediaUploadButton'
import { isBlobUrl } from '@editor/common/utils'
import { ImageUploadInfo } from '@editor/common/utils/image'
import { optimizeImage } from '@actions/utility'
import { faviconSizes } from '@editor/conf/imageSizes'

const Favicon = ({ favicon, setFavicon }: TFaviconProps) => {
  const faviconUrl = favicon.toString()
  const { siteId } = useParams()

  const removeFavicon = useCallback(() => {
    setFavicon(
      new ImageUploadInfo({ failed: false, isLoading: false, url: '' })
    )
  }, [setFavicon])

  const handleChoose = useCallback(
    (url: string) => {
      setFavicon(
        new ImageUploadInfo({ failed: false, isLoading: true, url: url })
      )

      if (isBlobUrl(url)) {
        return
      }

      optimizeImage(url, faviconSizes, siteId)
        .then(({ imgDimensions }) => {
          setFavicon(
            new ImageUploadInfo({
              failed: false,
              isLoading: false,
              url: imgDimensions.small
            })
          )
        })
        .catch(() => {
          setFavicon(
            new ImageUploadInfo({
              failed: true,
              isLoading: false,
              url
            })
          )
        })
    },
    [setFavicon, siteId]
  )

  const handleError = useCallback(() => {
    setFavicon(
      new ImageUploadInfo({
        failed: true,
        isLoading: false,
        url: faviconUrl || BLANK_IMAGE_URL
      })
    )
  }, [setFavicon, faviconUrl])

  return (
    <Styled.Container>
      <TitleContainer
        title={translate('favicon_label')}
        tooltipText={translate('format_tooltip_text')}
        imgSrc={FAVICON_TOOLTIP_IMAGE}
        hasTooltipContent
        isTooltipFromRight
      />
      <Styled.MainContent>
        <Styled.ImageWrapper>
          {faviconUrl ? (
            <Image
              as={Styled.Image}
              defaultImgSrc={favicon}
              sizes={{
                small: faviconUrl,
                medium: faviconUrl,
                large: faviconUrl,
                xlarge: faviconUrl
              }}
              alt="favicon"
            />
          ) : (
            <Styled.Image src={FAVICON_IMAGE} alt="favicon" />
          )}
        </Styled.ImageWrapper>
        <div>
          <ButtonsWrapper>
            <MediaUploadButton
              disabled={favicon.isLoading}
              accept="image/vnd.microsoft.icon, image/png"
              insert={handleChoose}
              onError={handleError}
            >
              <Button isReplace={!!faviconUrl} />
            </MediaUploadButton>

            {faviconUrl && !favicon.isLoading ? (
              <RemoveButton onClick={removeFavicon}>
                {translate('remove_label')}
              </RemoveButton>
            ) : null}
          </ButtonsWrapper>
          <UploadDescription>
            <b>{translate('recommended_size_label')}</b> {FAVICON_SIZE},{' '}
            <b>{translate('format_label')}</b> {FAVICON_FORMAT}
          </UploadDescription>
        </div>
      </Styled.MainContent>
    </Styled.Container>
  )
}

export default Favicon
